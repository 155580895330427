<template>
  <v-card flat class="product-view">
    <v-row class="mt-3 mb-2 mx-0">
      <v-col cols="12" class="pa-0 pl-1 pt-1">
        <v-autocomplete outlined dense :label="$t('message.projects.project')" :items="listOfProjects" hide-details v-model.number="projectId" @change="getProjectAndMaterils()" item-text="projectName" item-value="id"></v-autocomplete>
      </v-col>
    </v-row>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-row class="ma-0" v-else>
      <v-card-text class="pt-0 pb-1">
        <v-row>
          <v-col cols="6" class="pb-0 pt-0">{{ $t('message.projects.project') }}: {{ orderModel.number }}</v-col>
          <v-col cols="6" class="pb-0 pt-0 text-right">{{ $t('message.order.delivery') }}: {{ orderModel.deliverydate | dateFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0 pl-3">{{ $t('message.projects.customer') }}: {{ orderModel.customer_name || '' }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0 pl-3">{{ orderModel.customeraddress_address || '' }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0 pl-3">{{ orderModel.customeraddress_address2 || '' }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0 pl-3">{{ orderModel.customeraddress_postcode || '' }} {{ orderModel.customeraddress_city || '' }}</v-col>
        </v-row>
      </v-card-text>
      <template v-if="listOfProducts && listOfProducts.length > 0">
        <v-card-text class="highlight pt-0 pb-0 pl-1">
          <v-row>
            <v-col cols="3" class="pa-0 pl-3 font-weight-bold">{{ $t('message.order.products') }}:</v-col>
            <v-col cols="3" class="pa-0 pl-3 font-weight-bold">{{ $t('message.deviation.description') }}:</v-col>
            <v-col cols="3" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.avail') }}</v-col>
            <v-col cols="3" class="pa-0 pl-3 text-right font-weight-bold">{{ $t('message.order.ordered') }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pa-0">
              <v-divider class="pa-0"></v-divider>
            </v-col>
          </v-row>
          <!-- Load list of products -->
          <v-row v-for="(product, index) in listOfProducts" :key="index">
            <v-col cols="6" class="pa-0 pl-3" :class="{ 'pb-1': (index === listOfProducts.length - 1)}">
              <v-row>
                <v-col cols="6" class="pa-0 pl-3 text-truncate">{{ product.isfreight ? '1000' : product.number }}</v-col>
                <v-col cols="6" class="pa-0 text-truncate" :title="product.producttext">{{ product.producttext }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="3" class="pa-0 pl-3 text-right">{{ product.product_available }}</v-col>
            <v-col cols="3" class="pa-0 pl-3 text-right">
              {{ product.qty }}
              <span class="caption">{{ product.unit_name }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="pickOrderHandler" :disabled="!isProjectActive">{{ $t('message.materials.pickProducts') }}</v-btn><!-- yetToPicked || disable if project is not active -->
          <!-- <v-btn color="primary" v-if="!yetToPicked" @click="completeOrder">{{ $t('message.order.complete') }}</v-btn> -->
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </v-row>
    <!-- Freight adding  -->
    <v-dialog v-model="freightObj.dialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('message.common.deleteConfirm') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t('message.order.addFreight') }}
          <v-form ref="freightForm">
            <v-row>
              <v-col cols="6">
                <v-text-field dense hide-details v-model="cost" :rules="$_norwegianNumberValidation"
                outlined :label="$t('message.order.cost')"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field dense hide-details v-model="price" :rules="$_norwegianNumberValidation"
                outlined :label="$t('message.order.price')"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" id="freightConfirm" :loading="freightObj.loading" @click="freightObj.onSuccess">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="primary" id="freightClose" @click="freightObj.onClose">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
import projectMixin from '@/mixins/projectMixin'
export default {
  mixins: [projectMixin],
  data () {
    return {
      projectId: parseInt(this.$route.params.project_id),
      projectName: '',
      listOfProjects: [],
      loading: false,
      orderId: 0,
      listOfProducts: [],
      orderModel: {},
      yetToPicked: false,
      freightObj: {},
      cost: '',
      price: '',
      role: this.$formatter.cloneVariable(this.$store.state.auth.role),
      isProjectActive: true
    }
  },
  created () {
    this.getAllProjects()
  },
  mounted () {
    this.getProjectAndMaterils()
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  methods: {
    isAdmin () {
      return ['admin', 'superadmin'].includes(this.role.slug)
    },
    getAllProjects () {
      this.getSetListItems(`projects/get_as_simplified_for_pwa?filter=${this.isAdmin() ? 4 : 2}`, 'listOfProjects') // 4 is all, 2 is myall
    },
    getSetListItems (url, listName) {
      this.$api.execute('get', url).then((response) => {
        if (response.data) {
          if (listName === 'listOfProjects') {
            const list = this.$formatter.cloneVariable(response.data)
            const timerProject = list.find(x => x.number === 'TM001')
            if (timerProject) {
              const indexOf = list.indexOf(timerProject)
              list.splice(indexOf, 1)
            }
            list.forEach(project => {
              project.projectName = this.setProjectname(project)
            })
            this[listName] = list
          } else this[listName] = response.data
        }
      })
    },
    getProjectAndMaterils () { // api method will result - project, materials
      this.loading = true
      this.yetToPicked = false
      hostAppApi.get(`${this.getHostRefApi}project/materials?user_id=${this.userId}&project_id=${this.projectId}`)
        .then((response) => {
          if (response.data) {
            let { order, materials } = response.data
            this.isProjectActive = order.is_active
            if (order.has_materials !== undefined && !order.has_materials) {
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.projects.projectMaterialDisabled') })
              this.$router.go(-1)
            } else {
              if (!materials || JSON.stringify(materials) === '{}') materials = []
              this.orderModel = order
              const list = materials
              list.map((x) => {
                x.qty = this.$formatter.absoluteNumber(x.qty)
                x.product_available = this.$formatter.absoluteNumber(x.product_available)
                x.pickedqty = +x.pickedqty
                if (x.product_type === 'work') x.product_available = 0
                if ((x.pickedqty === null || x.pickedqty <= 0) && !x.ispackage) this.yetToPicked = true // = added by vinoth
                return x
              })
              this.listOfProducts = list
            }
          }
        })
        .finally(() => { this.loading = false })
    },
    getOrderInfo () {
      const model = {
        expand: [{
          select: ['name'],
          model: 'Customer',
          on_field: 'id',
          on_parent_field: 'customerid'
        }, {
          select: ['address', 'address2', 'postcode', 'city'],
          model: 'CustomerAddress',
          on_field: 'id',
          on_parent_field: 'deliveryaddress'
        }],
        filter: `id eq '${this.orderId}'`
      }
      this.$api.execute('post', 'orders/query', model)
        .then((response) => {
          if (response.data) {
            this.orderModel = response.data[0]
          }
        })
    },
    getMaterialProducts () {
      this.yetToPicked = false
      const model = {
        expand: [{
          model: 'Product',
          on_field: 'id',
          on_parent_field: 'productid',
          select: ['id', 'number', 'name', 'available', 'unitid', 'type']
        }, {
          model: 'ProductUnit',
          on_field: 'id',
          on_parent_field: 'unitid',
          select: ['name']
        }],
        filter: `orderid eq '${this.orderId}'`
      }
      this.$api.execute('post', 'ordermaterials/query', model)
        .then((response) => {
          if (response.data) {
            const list = response.data
            list.map((x) => {
              x.qty = this.$formatter.absoluteNumber(x.qty)
              x.product_available = this.$formatter.absoluteNumber(x.product_available)
              if (x.product_type === 'work') x.product_available = 0
              if ((x.pickedqty === null || x.pickedqty < 0)) this.yetToPicked = true
              return x
            })
            this.listOfProducts = list
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    pickOrderHandler () {
      this.$router.push(`/projects/${this.projectId}/materials/pick`)
    },
    completeOrder () {
      this.freightObj = {
        dialog: true,
        onSuccess: () => {
          this.freightObj.loading = true
          this.addFrieghtHandler(true)
        },
        onClose: () => {
          this.addFrieghtHandler(false)
        }
      }
    },
    addFrieghtHandler (type) {
      if (this.$refs.freightForm.validate()) {
        const model = this.$formatter.cloneVariable(this.orderModel)
        model.orderid = model.id
        model.id = 0
        model.costprice = this.$formatter.replaceCommaWithDot(this.cost)
        model.total = this.$formatter.replaceCommaWithDot(this.price)
        model.price = this.$formatter.replaceCommaWithDot(this.price)
        this.freightObj.loading = false
        this.freightObj.dialog = false
        this.$api.execute('post', `ordermaterials/add_freight?add_row=${type}`, model)
          .then((response) => {
            this.freightObj.loading = false
            this.freightObj.dialog = false
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t('message.order.completedSuccess') })
            this.$router.push('/customer_orders')
          })
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
        this.freightObj.loading = false
      }
    }
  }
}
</script>
